import { http, newResults, defined } from './request.js'
import { api as filesApi } from './files.js'

class Term {
  constructor() {
    this.id = null
    this.name = null
    this.tags = null
    this.categories = null
    this.teaser = null
    this.teaserSource = null
    this.description = null
    this.descriptionSource = null
    this.typos = null
    this.audio = null
    this.published = null
    this.request = null
    this.deleted = false
    this.bookmark = null
    this.files = null
  }
}

export const api = {
  /**
   * Создает пустой объект слова.
   * @returns {Term}
   */
  create() {
    return new Term()
  },

  parse: function(data) {
    const term = this.create()

    if (defined(data.uuid)) {
      term.id = data.uuid
    }
    if (defined(data.title)) {
      term.name = data.title
    }
    if (defined(data.subtitle)) {
      term.teaser = data.subtitle
    }
    if (defined(data.subtitle_source)) {
      term.teaserSource = data.subtitle_source
    }
    if (defined(data.description)) {
      term.description = data.description
    }
    if (defined(data.description_source)) {
      term.descriptionSource = data.description_source
    }
    if (data.audio_uri) {
      term.audio = {
        url: `/${data.audio_uri}`,
        name: data.audio_title,
      }
    }
    if (defined(data.typos)) {
      term.typos = data.typos.join(', ')
    }
    if (defined(data.published)) {
      term.published = data.published
    }
    if (defined(data.deleted)) {
      term.deleted = data.deleted
    }

    return term
  },

  findBy: async function(filter = {}) {
    const results = newResults()

    const params = []
    if (defined(filter.page)) {
      params['page'] = filter.page - 1
    }
    if (defined(filter.q)) {
      params['title'] = filter.q
    }
    if (defined(filter.letter)) {
      params['first_symbol'] = filter.letter
    }
    if (defined(filter.tag)) {
      params['tags'] = filter.tag.id ? filter.tag.id : filter.tag
    }
    if (defined(filter.category)) {
      params['category'] = filter.category.id ? filter.category.id : filter.category
    }
    if (filter.publishedOnly) {
      params['published_only'] = true
    }
    if (filter.limit) {
      params['items_per_page'] = filter.limit
    }
    params['sort_by'] = 'title'

    const response = await http.get('/terms', params)

    if (response.terms) {
      response.terms.forEach(termData => {
        const term = this.parse(termData)
        results.push(term)
      })

      results.meta.pages = parseInt(response?.filter?.pager?.total_pages)
      results.meta.total = parseInt(response?.filter?.pager?.total_items)
    }

    return results
  },

  find: async function(id) {
    const responseData = await http.get(`/terms/${id}`)

    const term = this.parse(responseData.term)
    if (responseData.bookmark) {
      term.bookmark = {
        id: responseData.bookmark['uuid']
      }
    }

    term.tags = await this.fetchTermTags(term)
    term.categories = await this.fetchTermCategories(term)
    term.files = await this.fetchTermFiles(term)

    return term
  },

  async fetchTermTags(term) {
    const tags = []

    const response = await http.get('/tags', {term: term.id})
    if (response.tags) {
      response.tags.forEach(tagData => {
        tags.push({id: tagData.uuid, name: tagData.title})
      })
    }

    return tags
  },

  async fetchTermCategories(term) {
    const tags = []

    const response = await http.get('/categories', {term: term.id})
    if (response.categories) {
      response.categories.forEach(categoryData => {
        tags.push({id: categoryData.uuid, name: categoryData.title})
      })
    }

    return tags
  },

  async fetchTermFiles(term) {
    const files = []

    const response = await http.get('/files', {terms: term.id})
    if (response.files) {
      response.files.forEach(fileData => {
        files.push(filesApi.parse(fileData))
      })
    }

    return files
  },

  /**
   * Возвращает буквы, по которым есть термины в системе.
   * @returns {Promise<{latin: *[], cyrillic: *[]}>}
   */
  getLetters: async function(publishedOnly = false) {
    const letters = {
      cyrillic: [],
      latin: [],
    }

    const params = {}
    if (publishedOnly) {
      params['published_only'] = true
    }
    const response = await http.get('/terms/first-letters', params)

    response['first_letters']['cyrillic'].forEach(letter => {
      letters.cyrillic.push(letter.toLowerCase())
    })
    response['first_letters']['latin'].forEach(letter => {
      letters.latin.push(letter.toLowerCase())
    })

    letters.cyrillic.sort((a, b) => {
      return a.localeCompare(b)
    })
    letters.latin.sort()

    return letters
  },

  search: async function(params) {
    return this
      .findBy()
      .then(terms => {
        let results = []

        if (params.q) {
          const q = params.q.toLowerCase()
          results = terms
            .filter(term => {
              return term.name.toLowerCase().indexOf(q) === 0
            })
            .map(term => {
              return {
                id: term.id,
                name: term.name,
                teaser: term.teaser,
              }
            })
        }

        return results
      })
  },

  save: async function(term) {
    const data = new FormData()

    if (defined(term.name)) {
      data.append('title', term.name)
    }
    if (defined(term.categories)) {
      term.categories.forEach(categoryId => {
        data.append('categories', categoryId)
      })
    }
    if (defined(term.tags)) {
      term.tags.forEach(tagName => {
        data.append('tags', tagName)
      })
    }
    if (defined(term.teaser)) {
      data.append('subtitle', term.teaser)
    }
    if (defined(term.teaserSource)) {
      data.append('subtitle_source', term.teaserSource)
    }
    if (defined(term.description)) {
      data.append('description', term.description)
    }
    if (defined(term.descriptionSource)) {
      data.append('description_source', term.descriptionSource)
    }
    if (defined(term.audio)) {
      if (term.audio instanceof File) {
        data.append('audio_file', term.audio)
      } else if (term.audio === false) {
        data.append('audio_file', null)
      }
    }
    if (defined(term.typos)) {
      term.typos.split(',').forEach(typo => {
        data.append('typos', typo.trim())
      })
    }
    if (defined(term.published)) {
      data.append('publish', term.published)
    }
    if (defined(term.request)) {
      data.append('term_request', term.request.id)
    }
    if (defined(term.files) && Array.isArray(term.files)) {
      term.files.forEach(file => {
        data.append('files', file.id)
      })
    }

    const responseData = !term.id
      ? await http.post('/terms', data)
      : await http.post(`/terms/${term.id}`, data)

    if (responseData.errors) {
      throw responseData.errors
    }

    if (responseData.term) {
      term.id = responseData.term.uuid
    }
    return await this.find(term.id)
  },

  /**
   * Загружает файл с терминами на сервер для импорта.
   * @param file
   * @returns {Promise<{id: *, lines: *[]}>}
   */
  uploadImportFile: async function(file) {
    const data = new FormData()
    data.append('file', file)
    const response = await http.post('/terms/import/upload', data)

    if (response.errors) {
      throw response.errors
    }

    const result = {
      id: response.import_file.uuid,
      lines: []
    }

    response.terms.forEach(line => {
      const similarTerms = []
      if (line.exists_terms) {
        line.exists_terms.forEach(termData => {
          similarTerms.push({
            id: termData.uuid,
            name: termData.title,
            teaser: termData.subtitle,
          })
        })
      }

      result.lines.push({
        number: line.line_number,
        name: line.title,
        teaser: line.subtitle,
        tags: line.tags,
        similarTerms: similarTerms.length ? similarTerms : null,
      })
    })

    return result
  },

  importTerms: async function(fileId, linesNumbers) {
    const data = new FormData()
    data.append('import_file', fileId)
    linesNumbers.forEach(lineNumber => {
      data.append('line_numbers', lineNumber)
    })

    return await http.post('/terms/import', data)
  },

  /**
   * Удаляет слово.
   * @param term
   * @returns {Promise<Term>}
   */
  delete: async function(term) {
    const json = await http.delete(`/terms/${term.id}`)
    if (json.errors) {
      throw json.errors
    }
    term.deleted = true
    return term
  },
}
