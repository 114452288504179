import * as request from './request.js'
import { api as authApi } from './auth.js'
import { api as termsApi } from './terms.js'
import { api as tagsApi } from './tags.js'
import { api as categoriesApi } from './categories.js'
import { api as usersApi } from './users.js'
import { api as analyticsApi } from './analytics.js'
import { api as bookmarksApi } from './bookmarks.js'
import { api as issuesApi } from './issues.js'
import { api as filesApi } from './files.js'

const api = {}

api.setToken = request.setToken
api.auth = authApi
api.tags = tagsApi
api.categories = categoriesApi
api.terms = termsApi
api.users = usersApi
api.analytics = analyticsApi
api.bookmarks = bookmarksApi
api.issues = issuesApi
api.files = filesApi

export default api
