import roles from './roles.js';

const menu = {}

menu[roles.administrator().id] = [
  {
    path: '/',
    icon: 'home',
    label: 'Главная',
  },
  {
    path: '/terms',
    icon: 'terms',
    label: 'Слова',
  },
  {
    path: '/tags',
    icon: 'tags',
    label: 'Теги',
  },
  {
    path: '/categories',
    icon: 'categories',
    label: 'Отрасли',
  },
  {
    path: '/users?role=student',
    icon: 'users',
    label: 'Пользователи',
  },
  {
    path: '/bookmarks',
    icon: 'bookmarks',
    label: 'Мои закладки',
  },
  {
    path: '/views',
    icon: 'views',
    label: 'История просмотров',
  },
]

menu[roles.editor().id] = [
  {
    path: '/',
    icon: 'home',
    label: 'Главная',
  },
  {
    path: '/terms',
    icon: 'terms',
    label: 'Слова',
  },
  {
    path: '/bookmarks',
    icon: 'bookmarks',
    label: 'Мои закладки',
  },
]

menu[roles.student().id] = [
  {
    path: '/',
    icon: 'home',
    label: 'Главная',
  },
  {
    path: '/bookmarks',
    icon: 'bookmarks',
    label: 'Мои закладки',
  },
  {
    path: '/views',
    icon: 'views',
    label: 'История просмотров',
  },
]

export default menu
