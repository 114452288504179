import './styles/styles.scss'
import lang from 'quasar/lang/ru.js'
import '@quasar/extras/material-icons/material-icons.css'
import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';

export default {
  config: {},
  plugins: { Dialog, Notify },
  lang: lang,
}
