<template>
  <q-layout view="hHh lpR fFf" class="loggined">
    <q-header elevated>
      <q-toolbar>
        <div class="col">
          <q-btn flat @click="showDrawer = !showDrawer" round dense class="menu xs">
            <img src="../assets/icons/menu.svg" alt="">
          </q-btn>

          <router-link to="/" class="logo gt-xs">
            <img src="../assets/logo.svg" alt="">
          </router-link>
        </div>

        <div class="text-center">
          <router-link to="/" class="title">Тезаурус</router-link>
        </div>

        <div class="col text-right">
          <div @click="showUserMenu" class="header-profile row justify-end no-wrap">
            <div class="header-profile__avatar">
              <user-avatar :user="user" />
            </div>
            <div class="header-profile__data gt-xs">
              <div class="header-profile__name ellipsis">{{ user.name }}</div>
              <div class="header-profile__role">{{ user.role.title }}</div>
            </div>
            <div class="header-profile__toggle gt-xs" :class="{'header-profile__toggle--opened': userMenuVisible}"></div>

            <q-menu class="header-profile-menu" anchor="bottom end" self="top end" :offset="getUserMenuOffset()">
              <q-list>
                <q-item clickable v-close-popup to="/profile">
                  <q-item-section avatar>
                    <q-icon>
                      <slot>
                        <img class="q-icon" src="../assets/icons/menu/profile.svg" alt="" />
                      </slot>
                    </q-icon>
                  </q-item-section>
                  <q-item-section>Посмотреть профиль</q-item-section>
                </q-item>
                <q-item v-if="canAddIssue" clickable v-close-popup @click="showAddIssueDialog">
                  <q-item-section avatar>
                    <q-icon>
                      <slot>
                        <img class="q-icon" src="../assets/icons/menu/support.svg" alt="" />
                      </slot>
                    </q-icon>
                  </q-item-section>
                  <q-item-section>Написать в поддержку</q-item-section>
                </q-item>
                <q-separator spaced />
                <q-item clickable v-close-popup @click="logout">
                  <q-item-section avatar>
                    <q-icon>
                      <slot>
                        <img class="q-icon" src="../assets/icons/menu/logout.svg" alt="" />
                      </slot>
                    </q-icon>
                  </q-item-section>
                  <q-item-section>Выйти из учетной записи</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </div>
        </div>
      </q-toolbar>
    </q-header>

    <q-drawer
      v-model="showDrawer"
      show-if-above
      :width="112"
      :breakpoint="600"
    >
      <div class="menu-header xs">
        Меню
        <img src="../assets/icons/close.svg" class="menu-header__close" @click="showDrawer = false" alt="Закрыть окно" title="Закрыть окно">
      </div>

      <q-list>
        <template v-for="menuItem in menu" :key="menuItem.path">
          <q-item :to="menuItem.path" :class="{'q-router-link--active': menuItem.active}" class="menu-item">
            <q-item-section>
              <div v-if="menuItem.icon" class="menu-item-icon row">
                <img :src="getMenuItemIcon(menuItem)" class="self-center" alt=""/>
              </div>
              <div class="menu-item-title">
                {{ menuItem.label }}
              </div>
            </q-item-section>
          </q-item>
        </template>
      </q-list>
    </q-drawer>

    <q-page-container>
      <q-page :class="pageClass">
        <q-ajax-bar
          ref="bar"
          position="top"
          color="accent"
          size="5px"
          skip-hijack
        />

        <router-view/>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
import {api as authApi} from '../api/auth.js'
import menu from '../menu.js';
import loading from '../loading.js'
import UserAvatar from '../blocks/UserAvatar.vue';
import EditIssueDialog from '../dialogs/issues/EditIssueDialog.vue'

export default {
  components: {
    UserAvatar,
  },
  setup() {
    return {
      canAddIssue: authApi.getCurrentUser().role.id === 'student'
    }
  },
  data() {
    return {
      showDrawer: false,
      userMenuVisible: false,
    }
  },
  created() {
    loading.startCallback = () => {
      this.$refs.bar.stop()
      this.$refs.bar.start()
    }
    loading.stopCallback = () => {
      this.$refs.bar.stop()
    }
  },
  methods: {
    logout() {
      this.$root.logout()
      this.$router.push({ name: 'login' })
    },
    getMenuItemIcon(menuItem) {
      var images = require.context('../assets/icons/', false, /\.svg$/)
      return images(`./${menuItem.icon}.svg`)
    },
    showUserMenu(e) {
      e.preventDefault()
      this.userMenuVisible = !this.userMenuVisible;
    },
    getUserMenuOffset() {
      if (this.$q.screen.lt.md) {
        return [-15, -5]
      }
      return [-30, 15]
    },
    showAddIssueDialog() {
      this.$root
        .createDialog({
          component: EditIssueDialog,
        })
    },
  },
  computed: {
    user() {
      return this.$store.state.currentUser
    },
    pageClass() {
      if (this.$route.name) {
        return 'page-' + this.$route.name.replace('_', '-')
      }
      return null
    },
    menu() {
      const items = menu[this.user.role.id]
      items.forEach(item => {
        item.active = false
      })

      if (authApi.getCurrentUser().role.id === 'student') {
        if (this.$route.path.startsWith('/terms')) {
          items[0].active = true
        }
      } else {
        items.forEach(item => {
          if (item.path !== '/') {
            const path = item.path.split('?')[0]
            if (this.$route.path.startsWith(path)) {
              item.active = true
            }
          }
        })
      }

      return items
    }
  },
}
</script>

<style lang="scss">
.q-page {
  padding: 30px;
}

.header-profile {
  display: inline-flex !important;
  text-align: left;
  font-family: Raleway, sans-serif;
  font-weight: 500;
  cursor: pointer;
  min-height: 54px;

  &:hover {
    text-decoration: none;
    background: #F3F3F3;
  }

  > div {
    align-self: center;
  }

  &__avatar {
    margin: 0 13px 0 13px
  }

  &__data {
    max-width: 70%;
  }

  &__name {
    font-size: 14px;
  }

  &__role {
    font-size: 12px;
    color: #969696;
  }

  &__toggle {
    width: 60px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      left: 10px;
      top: -14px;
      border-top: 1px solid #969696;
      border-right: 1px solid #969696;
      border-radius: 2px;
      transform: rotate(135deg);
    }

    &--opened::before {
      transform: rotate(-45deg);
      top: -10px;
    }
  }
}

.header-profile-menu {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 5px 0;
  font-size: 14px;

  .q-item {
    padding: 0 32px;
  }

  .q-icon {
    height: 18px;
  }

  .q-item__section--avatar {
    min-width: 28px;
    width: 32px;
  }

  .q-separator {
    margin: 8px 16px 0 !important;
  }
}

</style>
