import { http } from './request.js'

export const api = {
  fetchMostActiveReaders: async function() {
    const response = await http.get('/users/best')
    const bests = response.bests || {}

    const users = {
      week: null,
      month: null,
      year: null,
    }

    if (bests.week) {
      users.week = {
        name: bests.week.title,
      }
    }

    if (bests.month) {
      users.month = {
        name: bests.month.title,
      }
    }

    if (bests.year) {
      users.year = {
        name: bests.year.title,
      }
    }

    return users
  },
  recordTermView: async function(term) {
    const data = new FormData()
    data.append('term', term.id)
    const response = await http.post('/term-views', data)
    if (typeof response.errors !== 'undefined') {
      throw response.errors
    }
  }
}
