import { http } from './request.js'

class File {
  constructor() {
    this.id = null
    this.name = null
    this.url = null
    this.thumbnail = null
    this.type = null
  }
}

export const api = {
  create() {
    return new File()
  },

  parse(data) {
    const file = this.create()

    if (data.uuid) {
      file.id = data.uuid
    }
    if (data.uri) {
      file.url = `/${data.uri}`
    }
    if (data.thumbnail) {
      file.thumbnailUrl = `/${data.thumbnail}`
    }
    if (data.title) {
      file.name = data.title
    }
    if (data.type) {
      file.type = data.type
    }

    return file
  },

  async uploadFile(file) {
    const data = new FormData()
    data.append('file', file)
    const responseData = await http.post('/files', data)

    if (responseData.errors) {
      throw responseData.errors
    }

    return this.parse(responseData.file)
  },

  async fetchTermFiles(term) {
    const responseData = await http.get(`/terms/${term.id}/files`)
    console.log(responseData.files)
  },
}
