import { http } from './request.js'
import store from '../store.js'
import roles from '../roles.js'

export const api = {
  guest() {
    return {
      id: null,
      email: null,
      name: 'Гость',
      role: roles.guest(),
    }
  },

  signIn: async function(email, password) {
    const formData = new FormData()
    formData.append('email', email)
    formData.append('password', password)

    return await http.post('/auth/sign-in', formData)
  },

  signOut() {
    localStorage.removeItem('thesaurus')
  },

  /**
   * Выполняет запрос на восстановление пароля.
   * @param email
   * @returns {Promise<*>}
   */
  async requestPassword(email) {
    const formData = new FormData()
    formData.append('email', email)

    const responseData = await http.post('/auth/password-request', formData)

    if (responseData.errors) {
      throw responseData.errors
    }

    return responseData
  },

  /**
   * Выполняет запрос на установку нового пароля после его восстановления.
   * @param token
   * @param password
   * @returns {Promise<*>}
   */
  async resetPassword(token, password) {
    const formData = new FormData()
    formData.append('token', token)
    formData.append('password', password)

    const responseData = await http.post('/auth/password-reset', formData)

    if (responseData.errors) {
      throw responseData.errors
    }

    return responseData
  },

  restoreUser() {
    const storageJson = localStorage.getItem('thesaurus')
    if (storageJson === null) {
      store.commit('updateUser', this.guest())
      return this.guest()
    }
    const storage = JSON.parse(storageJson)
    if (typeof storage.user === 'undefined') {
      store.commit('updateUser', this.guest())
      return this.guest()
    }
    store.commit('updateUser', storage.user)
    return storage.user
  },

  storeUser(user) {
    const data = { user: user }
    localStorage.setItem('thesaurus', JSON.stringify(data));
    store.commit('updateUser', user)
  },

  getCurrentUser() {
    return store.state.currentUser
  }
}
