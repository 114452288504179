import { createRouter, createWebHistory } from 'vue-router'
import store from './store.js'
import roles from './roles.js'

const commonRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "home" */ './pages/LoginPage.vue'),
    meta: {
      title: 'Вход',
      access: { roles: [roles.guest()] },
    },
  },
  {
    path: '/password-reset',
    name: 'password_reset',
    component: () => import(/* webpackChunkName: "home" */ './pages/PasswordResetPage.vue'),
    meta: {
      title: 'Восстановление пароля',
      access: { roles: [roles.guest()] },
    },
  },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ './pages/HomePage.vue'),
    meta: {
      title: 'Главная',
      access: { roles: roles.loginables() },
    },
  },
]

const usersRoutes = [
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "users/profile" */ './pages/users/ProfilePage.vue'),
    meta: {
      title: 'Мой профиль',
      access: { roles: roles.all() },
    },
  },
  {
    path: '/users',
    name: 'users_list',
    component: () => import(/* webpackChunkName: "users/list" */ './pages/users/UsersPage.vue'),
    meta: {
      title: 'Пользователи',
      access: { roles: roles.administrators() },
    },
  },
  {
    path: '/users/:id',
    name: 'users_view',
    component: () => import(/* webpackChunkName: "users/view" */ './pages/users/ViewUserPage.vue'),
    meta: {
      title: 'Просмотр пользователя',
      access: { roles: roles.administrators() },
    }
  },
]

const categoriesRoutes = [
  {
    path: '/categories',
    name: 'categories_list',
    component: () => import(/* webpackChunkName: "categories/list" */ './pages/categories/CategoriesPage.vue'),
    meta: {
      title: 'Категории',
      access: { roles: roles.editors() },
    },
  },
  {
    path: '/categories/:id',
    name: 'categories_view',
    component: () => import(/* webpackChunkName: "categories/view" */ './pages/categories/ViewCategoryPage.vue'),
    meta: {
      title: 'Просмотр отрасли',
      access: { roles: roles.editors() },
    }
  },
]

const tagsRoutes = [
  {
    path: '/tags',
    name: 'tags_list',
    component: () => import(/* webpackChunkName: "tags/list" */ './pages/tags/TagsPage.vue'),
    meta: {
      title: 'Теги',
      access: { roles: roles.editors() },
    },
  },
  {
    path: '/tags/:id',
    name: 'tags_view',
    component: () => import(/* webpackChunkName: "tags/view" */ './pages/tags/ViewTagPage.vue'),
    meta: {
      title: 'Просмотр тега',
      access: { roles: roles.loginables() },
    }
  },
]

const termsRoutes = [
  {
    path: '/terms',
    name: 'terms_list',
    component: () => import(/* webpackChunkName: "terms/list" */ './pages/terms/TermsPage.vue'),
    meta: {
      title: 'Слова',
      access: { roles: roles.editors() },
    },
  },
  {
    path: '/terms/:id',
    name: 'terms_view',
    component: () => import(/* webpackChunkName: "terms/view" */ './pages/terms/ViewTermPage.vue'),
    meta: {
      title: 'Просмотр слова',
      access: { roles: roles.loginables() },
    }
  },
]

const termsRequestsRoutes = [
  {
    path: '/terms/requests',
    name: 'requests_list',
    component: () => import(/* webpackChunkName: "requests/list" */ './pages/termsRequests/TermsRequestsPage.vue'),
    meta: {
      title: 'Предложенные слова',
      access: { roles: roles.all() },
    },
  },
]

const bookmarksRoutes = [
  {
    path: '/bookmarks',
    name: 'bookmarks_list',
    component: () => import(/* webpackChunkName: "bookmarks/list" */ './pages/bookmarks/BookmarksPage.vue'),
    meta: {
      title: 'Закладки',
      access: { roles: roles.all() },
    },
  },
]

const viewsRoutes = [
  {
    path: '/views',
    name: 'views_list',
    component: () => import(/* webpackChunkName: "views/list" */ './pages/views/ViewsPage.vue'),
    meta: {
      title: 'История просмотров',
      access: { roles: roles.all() },
    },
  },
  {
    path: '/users/:id/views',
    name: 'views_user_list',
    component: () => import(/* webpackChunkName: "views/user" */ './pages/views/UserViewsPage.vue'),
    meta: {
      title: 'История просмотров',
      access: { roles: roles.administrators() },
    },
  },
]

const routes = [
  ...commonRoutes,
  ...usersRoutes,
  ...categoriesRoutes,
  ...tagsRoutes,
  ...termsRoutes,
  ...termsRequestsRoutes,
  ...bookmarksRoutes,
  ...viewsRoutes,
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const accessRoles = to.meta.access.roles || []
  const user = store.state.currentUser

  const updateTitle = (title) => {
    title = title ? title + ' | Тезаурус' : 'Тезаурус'
    document.title = title
  }

  if (user.id === roles.guest().id) {
    if (to.name !== 'login') {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      updateTitle(to.meta.title || '')
      next()
    }
  } else {
    if (accessRoles.find(role => role.id === user.role.id) === undefined) {
      next(false)
    } else {
      next()
    }
  }
})

export default router
