import {http, newResults} from './request.js'

class Bookmark {
  constructor() {
    this.id = null
    this.term = null
  }
}

export const api = {
  create: function () {
    return new Bookmark()
  },

  parse(data) {
    const bookmark = this.create()

    if (typeof data.uuid !== 'undefined') {
      bookmark.id = data.uuid
    }

    if (typeof data.term_uuid !== 'undefined') {
      bookmark.term = {
        id: data.term_uuid,
        name: data.term_title,
        teaser: data.term_subtitle,
      }
    }

    return bookmark
  },

  findBy: async function(filter = {}) {
    const results = newResults()

    const params = {}
    if (filter.page >= 1) {
      params['page'] = filter.page - 1
    }

    const response = await http.get('/bookmarks', params)

    if (response.bookmarks && response.bookmarks.length > 0) {
      response.bookmarks.forEach(bookmarkData => {
        const bookmark = this.parse(bookmarkData)
        results.push(bookmark)
      })
    }

    results.withResponseFilter(response.filter)

    if (response.errors) {
      throw response.errors
    }

    return results
  },

  addToBookmarks: async function(term) {
    const data = new FormData()
    data.append('term', term.id)
    const responseData = await http.post('/bookmarks', data)
    if (responseData.bookmark) {
      return {
        id: responseData.bookmark.uuid
      }
    }
    return null
  },

  delete: async function(bookmark) {
    return await http.delete(`/bookmarks/${bookmark.id}`)
  },
}
