import { http, newResults, defined } from './request.js'

class Tag {
  constructor() {
    this.id = null
    this.name = null
    this.description = null
    this.termsCount = 0
    this.deleted = false
  }
}

export const api = {
  /**
   * Создает новый объект тега.
   * @returns {Tag}
   */
  create: function() {
    return new Tag()
  },

  parse: function(data) {
    const tag = this.create()

    if (defined(data.uuid)) {
      tag.id = data.uuid
    }
    if (defined(data.title)) {
      tag.name = data.title
    }
    if (defined(data.description)) {
      tag.description = data.description
    }
    if (defined(data.terms_count)) {
      tag.termsCount = parseInt(data.terms_count)
    }

    return tag
  },

  /**
   * Возвращает список тегов, соответствующих условию.
   * @returns {Promise<Results>}
   */
  findBy: async function(filter = {}) {
    const results = newResults()

    const params = {}
    if (filter.page) {
      params['page'] = filter.page - 1
    }
    if (filter.perPage) {
      params['items_per_page'] = filter.perPage
    }
    params['sort_by'] = 'title'

    const json = await http.get('/tags', params)

    if (json.tags) {
      json.tags.forEach(tagData => {
        const tag = this.parse(tagData)
        results.push(tag)
      })

      results.meta.pages = parseInt(json?.filter?.pager?.total_pages)
      results.meta.total = parseInt(json?.filter?.pager?.total_items)
    }

    return results
  },

  /**
   * Возвращает тег по его идентификатору.
   * @param id
   * @returns {Promise<Tag>}
   */
  find: async function(id) {
    return http.get(`/tags/${id}`)
      .then(response => {
        return this.parse(response.tag)
      })
      .catch(response => {
        console.log(response)
      })
  },

  /**
   * Сохраняет тег.
   */
  save: async function(tag) {
    const data = new FormData()

    if (defined(tag.name)) {
      data.append('title', tag.name)
    }
    if (defined(tag.description)) {
      data.append('description', tag.description)
    }

    const responseData = !tag.id
      ? await http.post('/tags', data)
      : await http.post(`/tags/${tag.id}`, data)

    if (responseData.errors) {
      throw responseData.errors
    }

    if (responseData.tag) {
      tag.id = responseData.tag.uuid
    }
    return await this.find(tag.id)
  },

  /**
   * Удаляет тег.
   */
  delete: async function(tag) {
    const json = await http.delete(`/tags/${tag.id}`)
    if (json.errors) {
      throw json.errors
    }
    tag.deleted = true
    return tag
  },
}
