<template>
  <q-layout view="hHh lpR fFf" class="guest">
    <div class="guest-page">
      <header>
        <img src="../assets/logo.svg" alt="">
      </header>
      <main>
        <router-view />
      </main>
      <footer>
        Тезаурус - {{ year }}
      </footer>
    </div>
  </q-layout>
</template>

<script>
export default {
  setup() {
    return {
      year: new Date().getFullYear()
    }
  },
}
</script>
