const guest = { id: 'guest', title: 'Гость' }
const student = { id: 'student', title: 'Студент' }
const editor = { id: 'editor', title: 'Редактор' }
const administrator = { id: 'administrator', title: 'Администратор' }

const all = [
  guest,
  student,
  editor,
  administrator,
]

const loginables = [
  student,
  editor,
  administrator,
]

const editors = [
  editor,
  administrator,
]

const administrators = [
  administrator,
]

export default {
  guest() { return guest },
  student() { return student },
  editor() { return editor },
  administrator() { return administrator },
  all() { return all },
  loginables() { return loginables },
  editors() { return editors },
  administrators() { return administrators },
  byId(id) { return all.find(role => role.id === id) },
}
