<template>
  <q-dialog ref="dialog" persistent @hide="hide">
    <q-card class="dialog-content">
      <q-btn class="dialog-close" flat round dense v-close-popup>
      <img src="../../assets/icons/close.svg" alt="Закрыть окно" title="Закрыть окно">
      </q-btn>
      <div class="dialog-header">
        <img src="../../assets/icons/dialog/add-issue.svg" class="dialog-header-icon" alt="">
        Новый запрос в службу поддержки
      </div>

      <q-banner v-if="error" inline-actions class="text-white bg-red">{{ error }}</q-banner>

      <p v-if="sent">
        Ваш запрос отправлен.
      </p>
      <q-form v-else @submit.prevent="onSubmit">
        <q-select
          v-model="form.type.value"
          label="Выберите тип запроса"
          outlined
          :options="form.type.options"
          :error="form.type.error != null"
          :error-message="form.type.error" />

        <q-input
          v-model="form.phone.value"
          label="Введите номер телефона"
          outlined
          :error="form.phone.error != null"
          :error-message="form.phone.error"
          maxlength="200"
        />

        <q-input
          v-model="form.description.value"
          label="Опишите ваш запрос"
          outlined
          required
          type="textarea"
        />

        <div class="dialog-actions">
          <q-btn label="Отменить" flat class="cancel-button" @click.prevent="onCancelClick" />
          <q-btn label="Отправить" type="submit" color="primary" :loading="processing" />
        </div>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { api as issuesApi } from '../../api/issues.js'

export default {
  name: 'EditIssueDialog',
  emits: ['ok'],
  data() {
    return {
      processing: false,
      error: null,

      form: {
        type: {
          value: 'Контент',
          error: null,
          options: ['Контент', 'Проблема', 'Другое'],
        },
        phone: {
          value: null,
          error: null,
        },
        description: {
          value: null,
          error: null,
        },
      },

      sent: false,
    }
  },
  methods: {
    show () {
      this.$refs.dialog.show()
    },
    hide () {
      this.$refs.dialog.hide()
    },
    onCancelClick () {
      this.hide()
    },
    onSubmit() {
      const issue = issuesApi.create()
      issue.type = this.form.type.value
      issue.phone = this.form.phone.value
      issue.description = this.form.description.value

      this.processing = true
      issuesApi
        .save(issue)
        .then(() => {
          this.sent = true
        })
        .catch(errors => {
          this.processing = false
          if (Array.isArray(errors)) {
            errors.forEach(error => {
              if (error.source) {
                switch (error.source) {
                  case 'type':
                    this.form.type.error = error.title
                    break
                  case 'contact':
                    this.form.phone.error = error.title
                    break
                  case 'description':
                    this.form.description.error = error.title
                    break
                }
              } else {
                this.error = error.title
              }
            })
          }
        })
    },
  },
}
</script>
