<template>
  <q-avatar>{{ getNameLetters(user.name) }}</q-avatar>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    }
  },
  methods: {
    getNameLetters(name) {
      let letters = ''
      const parts = name.split(' ')
      if (parts[0]) {
        letters += parts[0].substr(0, 1)
      }
      if (parts[1]) {
        letters += parts[1].substr(0, 1)
      }
      return letters.toUpperCase()
    },
  },
}
</script>

<style>
.q-avatar {
  color: #FFFFFF;
  background-color: #6BC3A9;
}
</style>
