import { http, defined } from './request.js'

class Issue {
  constructor() {
    this.id = null
    this.type = null
    this.phone = null
    this.description = null
  }
}

export const api = {
  /**
   * Создает пустой объект обращения.
   * @returns {Issue}
   */
  create: function() {
    return new Issue()
  },

  /**
   * По данным обращения формирует объект обращения.
   * @param data
   * @returns {Issue}
   */
  parse: function(data) {
    const issue = this.create()

    if (defined(data.uuid)) {
      issue.id = data.uuid
    }

    return issue
  },

  /**
   * Сохраняет обращение.
   * @param issue
   * @returns {Promise<Issue|*>}
   */
  save: async function(issue) {
    const data = new FormData()

    if (defined(issue.type)) {
      data.append('type', issue.type)
    }
    if (defined(issue.phone)) {
      data.append('contact', issue.phone)
    }
    if (defined(issue.description)) {
      data.append('description', issue.description)
    }

    const responseData = await http.post('/issues', data)

    if (responseData.errors) {
      throw responseData.errors
    }

    if (responseData.issue) {
      return this.parse(responseData.issue)
    }

    return issue
  },
}
