<template>
  <component :is="layout" />
</template>

<script>
import GuestLayout from './layouts/GuestLayout.vue'
import LoginedLayout from './layouts/LoginedLayout.vue'
import Login from './pages/LoginPage.vue'
import roles from './roles.js'
import { http } from './api/request.js'
import api from './api/api.js'

export default {
  created() {
    const a = () => {
      const user = api.auth.restoreUser()
      if (user.token) {
        api.setToken(user.token)
      } else {
        if (!this.accessForGuests()) {
          this.$router.push({name: 'login'})
        }
      }
    }
    a()

    http.invalidTokenCallback = () => {
      api.auth.signOut()
      this.$store.commit('updateUser', api.auth.guest())
      this.$router.push({ name: 'login' })
    }

    window.addEventListener('storage', function () {
      console.log('storage changed')
      a()
    })
  },
  methods: {
    accessForGuests() {
      const paths = ['/login', '/password-reset']
      return paths.includes(window.location.pathname)
    },
    login(email, password) {
      return api.auth.signIn(email, password)
        .then(response => {
          const user = {
            id: response.account.uuid,
            email: response.account.email,
            role: roles.byId(response.account.role.Code),
            name: response.account.title,
            token: response.result,
          }

          this.$store.commit('updateUser', user)
          api.auth.storeUser(user)
          api.setToken(this.user.token)

          return user
        })
    },
    logout() {
      this.$store.commit('updateUser', api.auth.guest())
      localStorage.clear()
    },
    createDialog(options) {
      return this.$q.dialog({
        persistent: true,
        ...options
      })
    },
    showSuccessMessage(message) {
      this.$q.notify({
        type: 'positive',
        position: 'bottom-right',
        message: message,
      })
    },
  },
  computed: {
    user() {
      return this.$store.state.currentUser
    },
    layout() {
      return this.user.role.id === roles.guest().id ? GuestLayout : LoginedLayout
    }
  },
  components: { login: Login },
}
</script>
