import { createStore } from 'vuex'
import { api as authApi } from './api/auth.js'

export default createStore({
  state() {
    return {
      currentUser: authApi.guest()
    }
  },
  mutations: {
    updateUser(state, user) {
      state.currentUser = user
    }
  }
})
