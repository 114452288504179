import { http, newResults } from './request.js'
import roles from '../roles.js';

class User {
  constructor() {
    this.id = null
    this.name = null
    this.email = null
    this.role = null
    this.registrationDate = null
    this.blocked = false
    this.deleted = false
    this.createdAt = null
    this.visitedAt = null
  }
}

export const api = {
  /**
   * Создает новый пустой объект пользователя.
   */
  create: function () {
    return new User()
  },

  parse(userData) {
    const user = new User()
    user.id = userData.uuid
    user.email = userData.email
    user.role = roles.byId(userData.role.Code)
    user.name = userData.title
    user.registrationDate = Date.parse(userData.created_at)
    user.blocked = userData.blocked ? true : false

    if (userData.created_at && (userData.created_at !== '0001-01-01T00:00:00Z')) {
      user.createdAt = new Date(userData.created_at)
    }

    if (userData.visited_at && (userData.visited_at !== '0001-01-01T00:00:00Z')) {
      user.visitedAt = new Date(userData.visited_at)
    }

    return user
  },

  /**
   * Ищет пользователей по параметрам.
   */
  findBy: async function(filter) {
    const results = newResults()

    filter = filter || {}

    const params = {}
    if (filter.page) {
      params['page'] = filter.page - 1
    }
    if (filter.q) {
      params['title'] = filter.q
    }
    if (filter.role) {
      params['role'] = filter.role
    }
    if (filter.status) {
      if (filter.status === 'active') {
        params['active_only'] = true
      }
      if (filter.status === 'blocked') {
        params['blocked_only'] = true
      }
    }
    params['sort_by'] = 'title'

    const jsonData = await http.get('/users', params)

    if (jsonData.users) {
      jsonData.users.forEach(userData => {
        results.push(this.parse(userData))
      })

      results.meta.pages = parseInt(jsonData?.filter?.pager?.total_pages)
    }

    if (jsonData.errors) {
      throw jsonData.errors
    }

    return results
  },

  /**
   * Ищет пользователя по его идентификатору.
   */
  find: async function(id) {
    const jsonData = await http.get(`/users/${id}`)
    if (jsonData.errors) {
      throw jsonData.errors
    }
    return this.parse(jsonData.user)
  },

  /**
   * Сохраняет данные пользователя.
   * @param {object} user Объект пользователя с данными.
   * @param {string} password Новый пароль пользователя.
   * @returns {Promise<User>}
   */
  async save(user, password) {
    const formData = new FormData();
    if (user.name) {
      formData.append('title', user.name)
    }
    if (user.email) {
      formData.append('email', user.email)
    }
    if (password) {
      formData.append('password', password)
    }
    if (user.role) {
      formData.append('role', user.role.id)
    }

    const jsonData = user.id
      ? await http.post(`/users/${user.id}`, formData)
      : await http.post('/users', formData)

    if (jsonData.errors) {
      throw jsonData.errors
    }

    if (jsonData.user) {
      return this.parse(jsonData.user)
    }

    return user
  },

  /**
   * @param {User} user
   * @param {string} name
   * @returns {Promise<User>}
   */
  async changeUserName(user, name) {
    const formData = new FormData();
    formData.append('title', name)

    const responseData = await http.post(`/users/${user.id}`, formData)

    if (responseData.errors) {
      throw responseData.errors
    }

    return await this.find(user.id)
  },

  /**
   * @param {User} user
   * @param {string} email
   * @param {password} password
   * @returns {Promise<User>}
   */
  async changeUserEmail(user, email, password) {
    const formData = new FormData();
    formData.append('email', email)
    formData.append('password_current', password)

    const responseData = await http.post(`/users/${user.id}`, formData)

    if (responseData.errors) {
      throw responseData.errors
    }

    return await this.find(user.id)
  },

  /**
   * @param {User} user
   * @param {string} newPassword
   * @param {string} password
   * @returns {Promise<User>}
   */
  async changeUserPassword(user, newPassword, password) {
    const formData = new FormData();
    formData.append('password', newPassword)
    formData.append('password_current', password)

    const responseData = await http.post(`/users/${user.id}`, formData)

    if (responseData.errors) {
      throw responseData.errors
    }

    return await this.find(user.id)
  },

  /**
   * Блокирует пользователя.
   */
  block: async function(user) {
    const jsonData = await http.post(`/users/${user.id}/block`)
    if (jsonData.errors) {
      throw jsonData.errors
    }
    user.blocked = true
    return user
  },

  /**
   * Разблокирует пользователя.
   */
  activate: async function(user) {
    const jsonData = await http.post(`/users/${user.id}/activate`)
    if (jsonData.errors) {
      throw jsonData.errors
    }
    user.blocked = false
    return user
  },

  /**
   * Удаляет пользователя.
   */
  delete: async function(user) {
    const jsonData = await http.delete(`/users/${user.id}`)
    if (jsonData.errors) {
      throw jsonData.errors
    }
    user.deleted = true
    return user
  },
}
