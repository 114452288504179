import { http, newResults, defined } from './request.js'

class Category {
  constructor() {
    this.id = null
    this.name = null
    this.level = '/'
    this.parent = null
    this.childrenCount = null
    this.children = []
    this.weight = 0
    this.termsCount = 0
    this.fullTermsCount = 0
  }
}

export const api = {
  root: {id: '00000000-0000-0000-0000-000000000000', name: ''},

  /**
   * Создает новый объект категории.
   * @returns {Category}
   */
  create: function () {
    return new Category()
  },

  parse: function(data) {
    const category = this.create()

    if (defined(data.uuid)) {
      category.id = data.uuid
    }
    if (defined(data.title)) {
      category.name = data.title
    }
    if (defined(data.parent_uuid)) {
      category.parent = { id: data.parent_uuid }
    } else {
      category.parent = {id: this.rootId, name: '-'}
    }
    if (defined(data.description)) {
      category.description = data.description
    }
    if (defined(data.weight)) {
      category.weight = parseInt(data.weight)
    }
    if (defined(data.children_count)) {
      category.childrenCount = parseInt(data.children_count)
    }
    if (defined(data.terms_count)) {
      category.termsCount = parseInt(data.terms_count)
    }

    return category
  },

  findBy: async function(filter = {}) {
    const params = {}

    if (filter.limit) {
      params['items_per_page'] = filter.limit
    }

    return http.get('/categories', params).then(response => {
      const results = newResults()

      if (response.categories) {
        response.categories = response.categories.sort((a, b) => {
          return parseInt(a.weight) > parseInt(b.weight) ? 1 : -1
        })

        response.categories.forEach(categoryData => {
          const category = this.parse(categoryData)
          results.push(category)
        })

        results.meta.pages = parseInt(response.filter?.pager?.total_pages || 0)
        results.meta.total = parseInt(response.filter?.pager?.total_items || 0)
      }

      return results
    })
  },

  findTree: async function() {
    const tree = []

    const results = await this.findBy({limit: 10000})

    const processLevel = (parent, level) => {
      const children = []
      results.forEach(category => {
        if (category.parent && category.parent.id === parent.id) {
          category.children = processLevel(category, level + 1)
          category.parent = { id: parent.id, name: parent.name }
          category.level = level
          children.push(category)
        }
      })
      return children
    }
    processLevel(this.root, 0).forEach(category => {
      tree.push(category)
    })

    // Соберем общее количество слов в поддереве для каждой категории.
    const collectChildrenTermsCounts = (categories) => {
      let count = 0
      categories.forEach(category => {
        category.fullTermsCount = category.termsCount + collectChildrenTermsCounts(category.children)
        count += category.fullTermsCount
      })
      return count
    }
    collectChildrenTermsCounts(tree)

    return tree
  },

  find: async function(id) {
    const responseData = await http.get(`/categories/${id}`)
    return this.parse(responseData.category)
  },

  save: async function(category) {
    const data = new FormData()

    if (defined(category.name)) {
      data.append('title', category.name)
    }
    if (defined(category.parent)) {
      data.append('parent', category.parent)
    }
    if (defined(category.description)) {
      data.append('description', category.description)
    }
    if (defined(category.weight)) {
      data.append('weight', category.weight)
    }

    const responseData = !category.id
      ? await http.post('/categories', data)
      : await http.post(`/categories/${category.id}`, data)

    if (responseData.errors) {
      throw responseData.errors
    }

    if (responseData.category) {
      category.id = responseData.category.uuid
    }
    return await this.find(category.id)
  },

  /**
   * Удаляет категорию.
   */
  delete: async function(category) {
    const responseData = await http.delete(`/categories/${category.id}`)
    if (responseData.errors) {
      throw responseData.errors
    }
    category.deleted = true
    return category
  },
}
