export default {
  startCallback: null,
  stopCallback: null,
  start() {
    this.startCallback()
  },
  stop() {
    this.stopCallback()
  },
  updateTitle(title) {
    title = title ? title + ' | Тезаурус' : 'Тезаурус'
    document.title = title
  },
}
