import { createApp } from 'vue'
import store from './store.js'
import router from './router.js'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'

const app = createApp(App)

app.mixin({
  methods: {
    pluralize(count, word1, word2, word5) {
      const cases = [2, 0, 1, 1, 1, 2]
      const words = [word1, word2, word5]
      return words[(count % 100 > 4 && count % 100 < 20) ? 2 : cases[Math.min(count % 10, 5)]]
    },
  }
})

app.use(store)
app.use(router)
app.use(Quasar, quasarUserOptions)

app.mount('#app')
