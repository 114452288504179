<template>
  <h1 class="login-title">{{ pageTitle }}</h1>

  <q-form @submit="onSubmit" :class="formClass">
    <q-input
      v-model="email"
      type="email"
      label="Электронная почта"
      :disable="processing"
      :error="error != null"
      :error-message="error"
      :error-label="error"
      @keyup="error = null"
      outlined
      required
      autofocus
      class="login-form__email"
    />

    <q-input
      v-if="mode === 'login'"
      v-model="password"
      :type="showPassword ? 'text' : 'password'"
      label="Пароль"
      :disable="processing"
      @keyup="error = null"
      outlined
      required
      class="login-form__password"
    >
      <template v-slot:append>
        <q-icon
          :name="showPassword ? 'visibility' : 'visibility_off'"
          class="cursor-pointer"
          @click="showPassword = !showPassword"
        />
      </template>
    </q-input>

    <div v-if="mode === 'login'" class="login-form__actions">
      <q-btn
        class="login-form__reset"
        label="Забыли пароль?"
        @click="mode = 'password-request'"
      />

      <q-btn
        class="login-form__login"
        type="submit"
        label="Войти в систему"
        :loading="processing"
      />
    </div>
    <div v-else class="login-form__actions">
      <q-btn
        class="login-form__reset"
        type="submit"
        label="Восстановить"
        :loading="processing"
      />

      <q-btn
        class="login-form__login"
        label="Войти в систему?"
        @click="mode = 'login'"
      />
    </div>
  </q-form>
</template>

<script>
import { api as authApi } from '../api/auth.js'

export default {
  created() {
    const currentUser = authApi.getCurrentUser()
    if (currentUser.role.id !== 'guest') {
      this.$router.push({name: 'home'})
    }
  },
  data() {
    return {
      email: null,
      password: null,
      showPassword: false,
      error: null,
      processing: false,
      mode: 'login',
    }
  },
  methods: {
    onSubmit() {
      if (this.mode === 'login') {
        this.processLoginForm()
      } else if (this.mode === 'password-request') {
        this.processPasswordRequestForm()
      }
    },
    processLoginForm() {
      this.processing = true
      this.$root.login(this.email, this.password)
        .then(() => {
          this.$router.push({name: 'home'})
        })
        .catch(errors => {
          this.processing = false
          if (Array.isArray(errors)) {
            this.error = errors[0].title
          } else {
            this.error = "Неправильное имя пользователя или пароль."
          }
        })
    },
    processPasswordRequestForm() {
      this.processing = true
      authApi.requestPassword(this.email)
        .then((response) => {
          this.processing = false
          console.log(response)
          this.$root.showSuccessMessage('Письмо с инструкциями выслано на почту.')
        })
        .catch(errors => {
          this.processing = false
          if (Array.isArray(errors)) {
            this.error = errors[0].title
          }
        })
    },
  },
  computed: {
    pageTitle() {
      return this.mode === 'password-request' ? 'Восстановление пароля' : 'Вход в систему'
    },
    formClass() {
      return 'login-form' + (this.mode === 'password-request' ? ' login-form--request' : '')
    },
  }
}
</script>

<style lang="scss">
@import "~@/spa/styles/quasar.variables.scss";

.login-title {
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 50px;

  @media (max-width: $breakpoint-xs-max) {
    margin-top: 10%;
    margin-bottom: 50px;
  }

  .loggined & {
    display: none;
  }
}

.login-form {
  width: 100%;
  max-width: 440px;
  padding: 0 30px;

  .loggined & {
    display: none;
  }

  .q-field__native[required] ~ .q-field__label:after {
    content: none;
  }

  .q-field {
    margin-bottom: 10px;
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 35px;
    gap: 20px;

    button {
      padding: 10px 15px;
      text-transform: uppercase;
      color: $primary;

      &[type="submit"] {
        background: $primary;
        color: #FFFFFF;
        text-transform: none;
        @media (max-width: $breakpoint-xs-max) {
          & {
            order: 2;
          }
        }
      }
    }

    @media (max-width: $breakpoint-xs-max) {
      flex-direction: column;
      margin-top: 45px;
      gap: 15px;

      button {
        flex: 0 0 100%
      }

      .login-form--request & {
        margin-top: 15px;
      }
    }
  }
}
</style>
