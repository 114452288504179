let token = null

function getHeaders() {
  const headers = {
    'Accept': 'application/json',
  }

  if (token) {
    headers['Authorization'] = 'Bearer ' + token
  }

  return headers
}

export function setToken(newToken) {
  token = newToken
}

export const http = {
  invalidTokenCallback() {},
  get: async function(url, params = null) {
    url = '/api' + url
    if (params) {
      const searchParams = new URLSearchParams()
      Object.keys(params).forEach(key => {
        searchParams.append(key, params[key])
      })
      url += '?' + searchParams
    }

    const response = await fetch(url, {
      headers: getHeaders(),
    }).catch(error => {
      console.log(error)
      alert('Error! ' + error.message)
    })

    if (response.status === 403) {
      alert('Доступ запрещен!')
      throw 'Доступ запрещен!'
    } else if (response.status === 401) {
      localStorage.removeItem('thesaurus')
      window.location.href = '/login'
    }

    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") === -1) {
      alert('Ошибка подключения к серверу!')
      throw 'Ошибка подключения к серверу!'
    }

    const responseJson = await response.json()
    if (responseJson.errors && responseJson.errors[0].source === 'token') {
      this.invalidTokenCallback()
    }
    return responseJson
  },
  post: async function(url, data = {}) {
    const response = await fetch('/api' + url, {
      method: 'POST',
      headers: getHeaders(),
      body: data
    })

    if (response.status === 403) {
      alert('Доступ запрещен!')
      throw 'Доступ запрещен!'
    } else if (response.status === 401) {
      localStorage.removeItem('thesaurus')
      window.location.href = '/login'
    }

    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") === -1) {
      alert('Ошибка подключения к серверу!')
      throw 'Ошибка подключения к серверу!'
    }

    return response.json()
  },
  delete: async function(url) {
    const response = await fetch('/api' + url, {
      method: 'DELETE',
      headers: getHeaders(),
    })

    if (response.status === 403) {
      alert('Доступ запрещен!')
      throw 'Доступ запрещен!'
    }

    return response.json()
  },
}

class Results extends Array {
  constructor() {
    super();
    this.meta = {
      page: 0,
      pages: 0,
      total: 0,
    }
  }

  static get [Symbol.species]() {
    return Array;
  }

  withResponseFilter(filter) {
    if (filter.pager) {
      const pager = filter.pager
      this.meta.page = pager.page + 1
      this.meta.pages = parseInt(pager.total_pages || 0)
      this.meta.total = parseInt(pager.total_items || 0)
    }
  }

  checkEmptyPage() {
    if ((this.meta.page > 1) && (this.length === 0)) {
      const url = new URL(window.location)
      const newPage = this.meta.page - 1
      if (newPage > 1) {
        url.searchParams.set('page', newPage)
      } else {
        url.searchParams.delete('page')
      }
      return url.pathname + url.search
    }
  }
}

export function newResults() {
  return new Results()
}

export function defined(value) {
  return value != undefined && value !== null
}

export const ZERO_ID = '00000000-0000-0000-0000-000000000000'
export function notZeroId(id) {
  return id !== ZERO_ID
}
